import axios from "axios";
export const getCoupons = async (currentPage) => {
    try {

        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/user/couponcode?page=${currentPage}&size=${10}`, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
                "project": "PIDILITE"
            }
        });

        return response;

    } catch (err) {
        return err.message
    }
}